import {
    Box,
    Container,
    Title,
    TypographyStylesProvider,
    createStyles
} from "@mantine/core";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import * as React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
  
  const useStyles = createStyles((theme) => ({
    pageTitle: {
      fontSize: "3rem",
      "&::after": {
        content: '""',
        display: "block",
        backgroundColor: "#b28a4f",
        transition: "width 0.3s ease-in-out",
        width: 100,
        height: 2,
        marginBottom: theme.spacing.xs,
      },
      ".mantine-Paper-root:hover &::after": {
        width: "100%",
      },
    },
  }));
  
  const ImpressumPage = ({ data }) => {
    const { classes } = useStyles();
  
    return (
      <Layout>
        <Box mt={56} w="100%">
          <Helmet titleTemplate="%s | Impressum" />
          <Container py="xl" size="lg" h="100vh" style={{overflowY: "auto", overflowX: "hidden"}}>
            <Title
              className={classes.pageTitle}
              order={1}
              color="rgba(50, 59, 60, 0.8)"
              ff="Oswald"
              ml="sm"
            >
              Impressum
            </Title>
            <Box px="sm">
              <TypographyStylesProvider mt={30}>
                <div
                  dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
                />
              </TypographyStylesProvider>
            </Box>
          </Container>
        </Box>
      </Layout>
    );
  };
  
  ImpressumPage.propTypes = {
    data: PropTypes.object.isRequired,
  };
  
  export default ImpressumPage;
  
  export const impressumPageQuery = graphql`
    query DatenschutzPage($id: String!) {
      markdownRemark(id: { eq: $id }) {
        html
        frontmatter {
          title
          phoneContacts {
            name
            phone
          }
          emailContacts {
            name
            email
          }
        }
      }
    }
  `;
  